import { render, staticRenderFns } from "./mybreadcrumb.vue?vue&type=template&id=998c8520&scoped=true&"
import script from "./mybreadcrumb.vue?vue&type=script&lang=js&"
export * from "./mybreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./mybreadcrumb.vue?vue&type=style&index=0&id=998c8520&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998c8520",
  null
  
)

export default component.exports