<template>
  <div class="mybreadcrumb" v-if="this.$route.path !== '/home'">
    <!-- 面包屑 router -->
    <div class="breadcrumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, index) in $route.meta"
          :key="index"
          :to="item.url"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped="scoped">
@import './mybreadcrumb.css';
</style>
