<template>
  <div class="financialproducts">
    <div class="financialproducts_top">
      <div class="financialproducts_mybreadcrumb">
        当前位置： <app-mybreadcrumb />
      </div>

      <div class="financialproducts_inp">
        <el-input
          placeholder="输入关键字搜索"
          suffix-icon="el-icon-search"
          v-model="keyword"
          @change="changeSelect"
        ></el-input>
      </div>
    </div>

    <div class="financialproducts_content">
      <section class="financialproducts_product">
        <div class="financialproducts_product_content">
          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">适用区域：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value1" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio :label="3126">全省</el-radio>
                  <el-radio
                    :label="item.id"
                    v-for="item in quCity"
                    :key="item.id"
                    >{{ item.name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">产品类型：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value2" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio
                    :label="item.id"
                    v-for="item in product"
                    :key="item.id"
                    >{{ item.cate_name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">担保方式：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value3" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio
                    :label="item.id"
                    v-for="item in guarantee"
                    :key="item.id"
                    >{{ item.cate_name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">贷款渠道：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value4" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio
                    :label="item.id"
                    v-for="item in channel"
                    :key="item.id"
                    >{{ item.cate_name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">产品标签：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value5" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio
                    :label="item.id"
                    v-for="item in labels"
                    :key="item.id"
                    >{{ item.cate_name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">融资期限：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value6" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio :label="1">6个月以下</el-radio>
                  <el-radio :label="2">6-12个月</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">融资额度：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value7" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio :label="1">0-100万</el-radio>
                  <el-radio :label="2">100-500万</el-radio>
                  <el-radio :label="3">500万以上</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">政策型产品：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value8" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="financialproducts_product_item">
            <div class="financialproducts_product_name">排序方式：</div>
            <div class="financialproducts_product_sele">
              <div class="financialproducts_product_items">
                <el-radio-group v-model="value9" @change="changeSelect">
                  <el-radio :label="-1">不限</el-radio>
                  <el-radio :label="1">申请数由高到低</el-radio>
                  <el-radio :label="2">申请数从低到高</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="financialproducts_list">
        <div
          class="financialproducts_list_good"
          v-for="item in listsItem"
          :key="item.id"
        >
          <div class="financialproducts_pic_box">
            <el-image :src="item.gallery" class="good_pic"></el-image>
          </div>

          <div
            class="financialproducts_good_rig"
            @click="toproductsdetail(item.id)"
          >
            <div class="financialproducts_good_top">
              <div class="financialproducts_good_name" :title="item.name">
                {{ item.name
                }}<span v-if="item.is_policy == 1">政策型产品</span>
              </div>

              <div class="financialproducts_good_detail">查看详情</div>
            </div>

            <div class="financialproducts_good_bm">
              <div class="financialproducts_good_message">
                <div class="financialproducts_good_label">
                  融资额度：
                  <span>{{ item.min_quota }}~{{ item.max_quota }}万</span>
                </div>
                <div class="financialproducts_good_label">
                  利率：<span>{{ item.min_rate }}%~{{ item.max_rate }}%</span>
                </div>
                <div class="financialproducts_good_label">
                  适用地区： <span>{{ item.area_name }}</span>
                </div>
                <!-- <div class="financialproducts_good_label">抵款期限： <span>{{item.min_credit}}~{{item.max_credit}}月</span></div> -->
                <div class="financialproducts_good_label">
                  担保： <span> {{ item.cate_name }}</span>
                </div>
              </div>

              <div
                class="financialproducts_good_apply"
                @click.stop="towebapply(item.id)"
              >
                立即申请
              </div>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="financialproducts_list_reportscreen">
          <div class="financialproducts_list_footer">
            <div class="financialproducts_list_mar">
              <app-mypaginations
                :pagSize="paginations"
                :allData="goodList"
                @setHandleCurrent="handleCurrent"
                @setHandleSize="handleSize"
                @setPage="setPage"
                @setSize="setSize"
              />
            </div>
          </div>
        </div>
      </section>

      <el-empty
        description="数据为空"
        v-if="flag == 0"
        :image-size="200"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb';
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';

export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      product: [],
      channel: [],
      guarantee: [],
      labels: [],
      keyword: '',
      value1: -1,
      value2: -1,
      value3: -1,
      value4: -1,
      value5: -1,
      value6: -1,
      value7: -1,
      value8: -1,
      value9: -1,
      goodList: [],
      count: 0,
      adopt: -1,
      nowTime: '',
      lastTime: '',
      listsItem: [],
      quCity: [],
      flag: 1,
    };
  },
  components: {
    'app-mybreadcrumb': MyBreadcrumb,
    'app-mypaginations': MyPaginations,
  },
  methods: {
    toproductsdetail(id) {
      this.$router.push({
        path: '/productsdetail',
        query: { ids: id, type: 2 },
      });
    },
    // 用户信息
    getVali(item) {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push({
                path: '/webproductapply',
                query: { ids: item, type: 2 },
              });
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            this.$message({
              message: '金融机构不能申请产品',
              type: 'error',
            });
          }
        }
      });
    },
    towebapply(item) {
      this.nowTime = new Date();

      if (this.nowTime - this.lastTime > 1500) {
        this.getVali(item);

        this.lastTime = this.nowTime;
      }
    },
    setPage(data) {
      this.paginations.page_index = data;

      this.getList();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getList();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.goodList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    // 适用地区
    getQhCityList() {
      let data = {};

      this.$post('/qh_city_lst', data).then((res) => {
        this.quCity = res.data.result ? res.data.result : [];
      });
    },
    // 分类   1:产品分类  2：担保  3： 渠道  4：标签
    getClass1() {
      this.$get('/pro_cate', { type: 1 }).then((res) => {
        if (res.data.status == 1) {
          this.product = !res.data.result ? [] : res.data.result;
        }
      });
    },
    getClass2() {
      this.$get('/pro_cate', { type: 3 }).then((res) => {
        this.channel = !res.data.result ? [] : res.data.result;
      });
    },
    getClass3() {
      this.$get('/pro_cate', { type: 2 }).then((res) => {
        this.guarantee = !res.data.result ? [] : res.data.result;
      });
    },
    getClass4() {
      this.$get('/pro_cate', { type: 4 }).then((res) => {
        this.labels = !res.data.result ? [] : res.data.result;
      });
    },
    changeSelect() {
      this.paginations.page_index = 1;
      this.getList();
    },
    // 产品列表
    getList() {
      let data = {
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        region_id: this.value1 == -1 ? '' : this.value1,
        cate_id: this.value2 == -1 ? '' : this.value2,
        guarantee_id: this.value3 == -1 ? '' : this.value3,
        channel_id: this.value4 == -1 ? '' : this.value4,
        label_id: this.value5 == -1 ? '' : this.value5,
        quota: this.value7 == -1 ? '' : this.value7,
        term: this.value6 == -1 ? '' : this.value6,
        is_policy: this.value8 == -1 ? '' : this.value8,
        req_num: this.value9 == -1 ? '' : this.value9,
        keyword: this.keyword,
      };

      this.$post('/product_list', data).then((res) => {
        if (!res.data.result) {
          this.goodList = [];
          this.count = 0;

          this.flag = 0;
        } else {
          this.goodList = res.data.result.length == 0 ? [] : res.data.result;
          this.count = res.data.count;

          this.flag = 1;
        }

        console.log(this.goodList);
        this.resetPaginations();
      });
    },
  },
  mounted() {
    if (this.$route.query.data) {
      this.value7 = JSON.parse(this.$route.query.data).a
        ? JSON.parse(this.$route.query.data).a
        : -1;
      this.value3 = JSON.parse(this.$route.query.data).b
        ? JSON.parse(this.$route.query.data).b
        : -1;
      this.value5 = JSON.parse(this.$route.query.data).c
        ? JSON.parse(this.$route.query.data).c
        : -1;

      console.log('dasadczxz', 0, this.value7, this.value3, this.value5);
    }

    this.getList();
    this.getClass1();
    this.getClass2();
    this.getClass3();
    this.getClass4();
    this.getQhCityList();
  },
};
</script>

<style scoped>
@import './financialproducts.css';
</style>
