import { render, staticRenderFns } from "./mypaginations.vue?vue&type=template&id=21a50f52&scoped=true&"
import script from "./mypaginations.vue?vue&type=script&lang=js&"
export * from "./mypaginations.vue?vue&type=script&lang=js&"
import style0 from "./mypaginations.vue?vue&type=style&index=0&id=21a50f52&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a50f52",
  null
  
)

export default component.exports